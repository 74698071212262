/** @jsx jsx */
import React from 'react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Grid, jsx, useColorMode } from 'theme-ui'
import { FluidImgPropType } from '../../Product/Grid/GridItem'
import useMatchMedia from '../../../hooks/utils/use-match-media'
import { CartContent } from './CartContent'
import { CartEmpty } from './CartEmpty'
import { CartHeader } from './CartHeader'
import { CartLoading } from './CartLoading'
import useLocaleCartLogic from '~/utils/intl/context/hooks/use-locale-cart-logic'

const CartPage = ({ cmsCartData }) => {
  //#region Hooks
  const isMobile = useMatchMedia('(max-width: 868px)')
  const [colorMode, setColorMode] = useColorMode('Cart')

  const { cart, isFetching } = useLocaleCartLogic()

  useEffect(() => {
    setColorMode('Cart')
  }, [setColorMode, colorMode])
  //#endregion

  const order = cart
  const isCartEmpty = order.lineItems && order.lineItems.length === 0
  const isLoading = isFetching && !order.lineItems
  // If only gift cards in order, hide shipping progress bar.
  const showShippingProgress =
    order.lineItems?.filter(item => item.giftCards?.length === 0)?.length > 0

  return (
    <>
      <CartHeader
        orderTotal={order?.total}
        isMobile={isMobile}
        showShippingProgress={showShippingProgress}
      />
      <Grid
        sx={{
          minHeight: 'max(80vh, 600px)',
          backgroundColor: '#F7EFD9',
          maxWidth: '1920px',
          m: 0,
          mx: 'auto',
          width: '100%',
        }}
      >
        {isLoading && <CartLoading />}
        {!isLoading && (
          <>
            {isCartEmpty && (
              <CartEmpty
                gatsbyImageData={cmsCartData.emptyCartImage.gatsbyImageData}
                text={cmsCartData.emptyCartText}
                cta={cmsCartData.emptyCartCta}
              />
            )}
            {!isCartEmpty && (
              <CartContent
                cmsCartData={cmsCartData}
                order={order}
                isMobile={isMobile}
                showShippingProgress={showShippingProgress}
              />
            )}
          </>
        )}
      </Grid>
    </>
  )
}

CartPage.propTypes = {
  data: PropTypes.shape({
    contentful_id: PropTypes.string.isRequired,
    emptyCartImage: FluidImgPropType,
    guarantee: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    itemUpsell: PropTypes.shape({
      comingSoon: PropTypes.bool,
      globalTradeItemNumber: PropTypes.string,
    }),
    kitUpsell: PropTypes.shape({}),
    longDonationDescription: PropTypes.string.isRequired,
    shippingNote: PropTypes.string.isRequired,
    shortDonationDescription: PropTypes.string.isRequired,
    showItemUpsell: PropTypes.bool,
    showKitUpsell: PropTypes.bool,
  }).isRequired,
}

export default CartPage
