/** @jsx jsx */
import React, { useContext, useState } from 'react'
import { jsx } from 'theme-ui'
import { useTranslate, utils } from '@chordcommerce/gatsby-theme-autonomy'
import { loadStripe } from '@stripe/stripe-js'
import { navigate } from 'gatsby'
import { useLocation } from 'react-use'
import { ChordCartContext } from '../../Mini-Cart/CartContext'
import { localeFromPathname, toURL } from '~/utils/intl/localePrefix'
import { checkForFilledGiftCards } from '~/utils/check-gift-cards'
import MessageContext from '~/contexts/MessageContext'
import DesktopCartContent from '~/components/Cart/Page/DesktopCartContent'
import MobileCartContent from '~/components/Cart/Page/MobileCartContent'
import useLocaleCartLogic from '~/utils/intl/context/hooks/use-locale-cart-logic'
import { useTikTokHelper } from '~/components/TikTokHelper'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK_KEY, {
  stripeAccount: process.env.GATSBY_STRIPE_CONNECTED_ACCOUNT,
})

export const CartContent = ({
  isMobile,
  cmsCartData,
  showShippingProgress,
}) => {
  const { initiateCheckout } = useTikTokHelper()
  const [, setMessage] = useContext(MessageContext)
  const [checkoutLoading, setCheckoutLoading] = useState(false)
  const translate = useTranslate()
  const { checkoutWithLocaleRestrictions: checkout, cart } =
    useLocaleCartLogic()

  const { lineItems } = cart
  const [apiError, setApiError] = React.useState(false)
  const context = useContext(ChordCartContext)
  const { getAllCartPromotionsForDisplay } = utils
  const promotions = getAllCartPromotionsForDisplay(cart)

  const location = useLocation()
  const amountPrefix =
    localeFromPathname(location.pathname).code === 'en-US' ? '' : 'US'

  const showILAddressNotice =
    localeFromPathname(location.pathname).code === 'he-IL'

  const creditAvailable =
    context?.displayTotalAvailableStoreCredit &&
    context.displayTotalAvailableStoreCredit !== '$0.00'

  const handleCheckout = async () => {
    setCheckoutLoading(true)

    try {
      if (!checkForFilledGiftCards(lineItems)) {
        setMessage('Please fill out all gift card recipient information')
        return
      }
      const cart = await checkout()
      await initiateCheckout()
      const stripe = await stripePromise
      stripe.redirectToCheckout({
        sessionId: cart.checkoutSessionId,
      })
    } catch (error) {
      setApiError(translate('error.api.default'))
      console.log(error, { source: 'CheckoutButton' })
    } finally {
      setCheckoutLoading(false)
    }
  }

  const handleContinueShopping = () => {
    navigate(toURL('/shop'))
  }

  return isMobile ? (
    <MobileCartContent
      amountPrefix={amountPrefix}
      creditAvailable={creditAvailable}
      handleCheckout={handleCheckout}
      promotions={promotions}
      apiError={apiError}
      checkoutLoading={checkoutLoading}
      handleContinueShopping={handleContinueShopping}
      cmsCartData={cmsCartData}
      showILAddressNotice={showILAddressNotice}
    />
  ) : (
    <DesktopCartContent
      amountPrefix={amountPrefix}
      creditAvailable={creditAvailable}
      handleCheckout={handleCheckout}
      promotions={promotions}
      apiError={apiError}
      checkoutLoading={checkoutLoading}
      handleContinueShopping={handleContinueShopping}
      cmsCartData={cmsCartData}
      showShippingProgress={showShippingProgress}
      showILAddressNotice={showILAddressNotice}
    />
  )
}
