/** @jsx jsx */
import React, { useEffect, useRef } from 'react'
import {
  useAnalytics,
  useTranslate,
} from '@chordcommerce/gatsby-theme-autonomy'

import { Flex, Grid, Box, Button, jsx, Link } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import Heading from '~/components/IntlComponents/Heading'
import Text from '~/components/IntlComponents/Text'
import { toURL } from '~/utils/intl/localePrefix'
import { productPathBuilder } from '~/utils/builder/index.cjs'
import useLocaleCartLogic from '~/utils/intl/context/hooks/use-locale-cart-logic'
import TriggerWarningAroundDisabledButtonsWrapper from '~/components/IntlComponents/TriggerWarningAroundDisabledButtonsWrapper'
import useVariantAvailability from '~/hooks/components/use-variant-availability'

export const UpsellCard = ({ item, onAdd }) => {
  const { hasGroupHitQuantityLimit } = useLocaleCartLogic()
  const { trackProductClicked } = useAnalytics()
  const translate = useTranslate()

  const {
    price,
    sku,
    mainImage,
    optionValues,
    product,
    soldOut,
    usePreFilledBundle,
  } = item
  const [{ slug, name }] = product

  const finalSku = usePreFilledBundle?.masterSku || sku

  const ref = useRef(null)
  const pageSlug = toURL(productPathBuilder(slug))

  const { isFetchingAvailability, isAvailable } = useVariantAvailability({
    finalSku,
  })

  const isDisabledFromIncreasingQuantity = hasGroupHitQuantityLimit(slug)
  useEffect(() => {
    if (ref.current?.classList) {
      ref.current.classList.add('shown')
    }
  }, [ref])

  const handleAdd = async finalSku => {
    if (!ref.current?.classList) return
    ref.current.classList.add('hidden')
    setTimeout(() => {
      if (!ref.current?.classList) return
      ref.current.classList.add('hidden-complete')
      ref.current.classList.remove('hidden')
      if (onAdd) {
        onAdd()
      }
    }, 500)

    setTimeout(() => window.dkAddToCart(finalSku, 1, false), 50)
  }

  return (
    <Flex
      ref={ref}
      className="upsell-card"
      sx={{
        minWidth: '264px',
        width: '100%',
        maxWidth: '296px',
        minHeight: '148px',
        border: '1px solid #FF5A4733',
        borderRadius: '10px',
        p: '20px',
        flexDirection: 'column',
      }}
    >
      <Grid
        sx={{
          gridTemplateColumns: '25% 75%',
          flex: 1,
          mb: '20px',
        }}
      >
        <Box>
          <Box
            sx={{
              width: '60px',
              height: '60px',
              borderRadius: '50%',
              overflow: 'hidden',
              border: '2px solid #FFDD00',
              mt: '-1px',
            }}
          >
            <GatsbyImage
              image={mainImage.gatsbyImageData}
              alt={mainImage?.title}
            />
          </Box>
        </Box>
        <Box>
          <Link
            href={pageSlug}
            onClick={() => trackProductClicked({ product })}
          >
            <Heading
              sx={{
                color: 'primary',
                fontFamily: 'heading',
                fontSize: ['16px', '18px'],
                lineHeight: '125%',
                textAlign: 'left',
                mb: '8px',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {name}
            </Heading>
          </Link>
          <Text as="p" sx={{ color: 'primary', fontSize: ['12px'] }}>
            {translate('cart.n_pack', { amount: optionValues[0].presentation })}
          </Text>
          <Text as="p" sx={{ color: 'primary', fontSize: ['12px'] }}>
            {translate('cart.one_time_purchase')}
          </Text>
        </Box>
      </Grid>
      <TriggerWarningAroundDisabledButtonsWrapper
        disabledByLocaleLimitations={isDisabledFromIncreasingQuantity}
      >
        <Button
          disabled={
            isDisabledFromIncreasingQuantity ||
            isFetchingAvailability ||
            !isAvailable ||
            soldOut
          }
          onClick={e => handleAdd(finalSku)}
          sx={{
            border: '1px solid #FF5A47',
            backgroundColor: 'white',
            color: 'primary',
            width: '100%',
            fontSize: ['12px', '14px'],
            textAlign: 'center',
            fontFamily: 'heading',
            lineHeight: '16px',
            py: '10px',
            height: 'auto',
            '&:hover': {
              backgroundColor: 'primary',
              color: 'white',
            },
          }}
        >
          {(!isAvailable && !isFetchingAvailability) || soldOut
            ? translate('product.sold_out_upsell')
            : translate('cart.quick_add', { amount: price })}
        </Button>
      </TriggerWarningAroundDisabledButtonsWrapper>
    </Flex>
  )
}
