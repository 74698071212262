/** @jsx jsx */
import React from 'react'
import { jsx, Flex, Box, Button, Spinner } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { merchantPriceFormatter } from '../../../utils/merchant-feed/price-formatter'
import { FreeShippingProgress } from '../FreeShippingProgress/FreeShippingProgress'
import useMatchMedia from '../../../hooks/utils/use-match-media'
import { CartUpsell } from './Upsell/CartUpsell'
import { CartList } from './CartList'
import { CartPagePromoCode } from './CartPagePromoCode'

import Text from '~/components/IntlComponents/Text'
import useLocaleCartLogic from '~/utils/intl/context/hooks/use-locale-cart-logic'
import { languages } from '~/utils/intl/localePrefix'
import Notice from '~/components/Generic/Notice'

const DesktopCartContent = ({
  promotions,
  handleCheckout,
  guarantee,
  cmsCartData,
  creditAvailable,
  checkoutLoading,
  showShippingProgress,
  handleContinueShopping,
  amountPrefix,
  showILAddressNotice,
}) => {
  const {
    cart: {
      itemTotal,
      total,
      displayTotalApplicableStoreCredit,
      displayOrderTotalAfterStoreCredit,
      displayStoreCreditRemainingAfterCapture,
    },
  } = useLocaleCartLogic()
  const { upsell } = cmsCartData || {}
  const translate = useTranslate()

  const showCheckoutCta = useMatchMedia('(max-width: 1024px)')

  const isUSA =
    typeof window !== 'undefined' &&
    (window.location.pathname.includes('/en-US') ||
      !languages.some(language => window.location.pathname.includes(language)))

  return (
    <Flex
      sx={{
        pt: '96px',
        pb: '48px',
        px: ['20px', '60px'],
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      }}
    >
      <Box sx={{ flex: 0.6 }}>
        <CartList isMobile={false} />
        <CartUpsell upsell={upsell} />
      </Box>

      <Box sx={{ flex: 0.3, pt: '64px', minWidth: '320px' }}>
        <Box
          sx={{
            border: '1px solid #FF5A47',
            mb: '24px',
            borderRadius: '10px',
            overflow: 'hidden',
          }}
        >
          {isUSA && showShippingProgress && (
            <Flex
              sx={{
                width: '100%',
                height: '81px',
                backgroundColor: 'primary',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <FreeShippingProgress orderTotal={total} />
            </Flex>
          )}
          <Box sx={{ p: '20px', borderBottom: '1px solid #FF5A4733' }}>
            <Flex>
              <Text
                as="p"
                sx={{
                  color: 'primary',
                  fontWeight: 400,
                  fontFamily: 'heading',
                  fontSize: ['14px', '16px'],
                  lineHeight: '20px',
                }}
              >
                {translate('cart.subtotal')}
              </Text>
              <Text
                as="p"
                sx={{
                  ml: 'auto',
                  color: 'primary',
                  fontSize: ['14px', '16px'],
                  lineHeight: '20px',
                  textAlign: 'right',
                }}
              >
                ${merchantPriceFormatter.format(parseFloat(itemTotal))}
              </Text>
            </Flex>

            {promotions?.map(({ label, amount }, i) => {
              const fmtLabel = label?.match(/\((.*)\)/)?.pop()
              const priceParts = merchantPriceFormatter
                .format(amount)
                .split('-')
              priceParts[0] = '-$'
              const fmtAmount = priceParts.join('')
              return (
                <Flex sx={{ mt: '16px' }} key={`promo_${i}`}>
                  <Text
                    as="p"
                    sx={{
                      color: 'primary',
                      fontWeight: 400,
                      fontFamily: 'heading',
                      fontSize: ['14px', '16px'],
                      lineHeight: '20px',
                    }}
                  >
                    {fmtLabel}
                  </Text>
                  <Text
                    as="p"
                    sx={{
                      ml: 'auto',
                      color: '#007300',
                      fontSize: ['14px', '16px'],
                      lineHeight: '20px',
                    }}
                  >
                    {fmtAmount}
                  </Text>
                </Flex>
              )
            })}

            {creditAvailable && (
              <>
                <Flex sx={{ mt: '16px' }}>
                  <Text
                    as="p"
                    sx={{
                      color: 'primary',
                      fontWeight: 400,
                      fontFamily: 'heading',
                      fontSize: ['14px', '16px'],
                      lineHeight: '20px',
                    }}
                  >
                    {translate('cart.store_credit_used')}
                  </Text>
                  <Text
                    as="p"
                    sx={{
                      ml: 'auto',
                      color: '#007300',
                      fontSize: ['14px', '16px'],
                      lineHeight: '20px',
                    }}
                  >
                    {displayTotalApplicableStoreCredit}
                  </Text>
                </Flex>
                <Flex sx={{ mt: '16px' }}>
                  <Text
                    as="p"
                    sx={{
                      color: 'primary',
                      fontWeight: 400,
                      fontFamily: 'heading',
                      fontSize: ['14px', '16px'],
                      lineHeight: '20px',
                    }}
                  >
                    {translate('cart.store_credit_remaining')}
                  </Text>
                  <Text
                    as="p"
                    sx={{
                      ml: 'auto',
                      color: '#007300',
                      fontSize: ['14px', '16px'],
                    }}
                  >
                    {displayStoreCreditRemainingAfterCapture}
                  </Text>
                </Flex>
              </>
            )}
          </Box>
          <Box sx={{ p: '20px' }}>
            <CartPagePromoCode />
          </Box>
          {showILAddressNotice && (
            <Box sx={{ p: '20px' }}>
              <Notice
                title={translate('cart.notice_title')}
                body={translate('cart.notice_body')}
                dir="rtl"
              />
            </Box>
          )}
        </Box>

        <div className="dk-flat-static"></div>

        <Button
          sx={{
            backgroundColor: 'secondary',
            width: '100%',
            color: '#FFDD00',
            mb: showCheckoutCta ? '0px' : '24px',
            '&:hover': {
              backgroundColor: 'primary',
              color: 'white',
            },
          }}
          onClick={handleCheckout}
        >
          {!checkoutLoading ? (
            <>
              {translate('cart.checkout')} <span sx={{ px: '4px' }}>•</span>{' '}
              {amountPrefix}
              {displayOrderTotalAfterStoreCredit}
            </>
          ) : (
            <Spinner color="#FFDD00" size="15" />
          )}
        </Button>
        {showCheckoutCta && (
          <Button
            variant="link"
            sx={{
              textAlign: 'center',
              mt: '20px',
              fontSize: '14px',
              lineHeight: '20px',
              width: '100%',
              fontFamily: 'heading',
              py: 0,
              mb: '16px',
            }}
            onClick={handleContinueShopping}
          >
            Continue Shopping
          </Button>
        )}
        <Text
          as="p"
          sx={{
            textAlign: 'center',
            fontSize: '12px',
            color: 'primary',
            fontFamily: 'monospace',
            fontWeight: 600,
            lineHeight: '15px',
            maxWidth: '411px',
            mx: 'auto',
          }}
        >
          {guarantee}
        </Text>
      </Box>
    </Flex>
  )
}

export default DesktopCartContent
