/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import PropTypes from 'prop-types'
import Text from '~/components/IntlComponents/Text'

const Notice = ({ title, body, ...props }) => {
  return (
    <Box
      sx={t => ({
        color: 'secondary',
        padding: '.5rem',
        border: `1px solid ${t.colors.secondary}`,

        borderRadius: '8px',
      })}
      {...props}
    >
      <Text
        sx={{
          fontWeight: 'bold',
          display: 'block',
          backgroundColor: 'primary',
          width: 'fit-content',
          borderRadius: '8px',
          p: '.2rem .5rem',
        }}
        dangerouslySetInnerHTML={{ __html: title }}
      />

      <Text dangerouslySetInnerHTML={{ __html: body }} />
    </Box>
  )
}

Notice.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
}

export default Notice
