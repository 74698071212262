/* @jsx jsx */
import React from 'react'
import { jsx, Button } from 'theme-ui'

export default function IconButton({ onClick, children, disabled }) {
  return (
    <Button
      onClick={onClick}
      variant="link"
      disabled={disabled}
      p="0"
      sx={{
        svg: {
          transition: 'all 0.2s ease',
          '.invert': {
            stroke: '#FF5A47',
            fill: '#FF5A47',
          },
        },
        '&:disabled': {
          svg: {
            opacity: 0.4,
          },
          cursor: 'not-allowed',
        },
        '&:hover:not([disabled])': {
          svg: {
            fill: '#FF5A47',
            path: {
              '&.invert': {
                stroke: '#F7EFD9',
              },
            },
          },
        },
      }}
    >
      {children}
    </Button>
  )
}
