/** @jsx jsx */
import React from 'react';
import { Box, jsx } from "theme-ui"
import Spinner from "../../Generic/Spinner"

export const CartLoading = () => {
  return (
    <Box
      sx={{
        textAlign: 'center',
        width: '100%',
        padding: ['10rem 1.25rem', '12rem 1.25rem'],
        marginRight: [null, '1rem'],
        marginBottom: ['1rem', null],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        
      }}
    >
      <Spinner size="50" />
    </Box>
  )
}