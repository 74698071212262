/** @jsx jsx */
import React from 'react'
import { Flex, Button, jsx } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import Text from '~/components/IntlComponents/Text'
import { toURL } from '~/utils/intl/localePrefix'

export const CartEmpty = ({ gatsbyImageData, text, cta }) => {
  return (
    <Flex
      sx={{
        textAlign: 'center',
        width: '100%',
        alignItems: 'center',
        flexFlow: 'column nowrap',
        py: ['111px', '222px'],
      }}
    >
      <GatsbyImage
        image={gatsbyImageData}
        alt={'Empty Cart'}
        imgStyle={{ width: '100%' }}
        style={{ width: '100%', maxWidth: 320, marginBottom: '48px' }}
      />
      <Text
        as="p"
        sx={{
          fontFamily: 'monospace',
          color: 'primary',
          fontWeight: 'bold',
          fontSize: '30px',
          lineHeight: 1,
          textAlign: 'center',
          maxWidth: 250,
          mb: '24px',
        }}
      >
        {text}
      </Text>
      <Link to={toURL('/shop')}>
        <Button sx={{ color: 'accent' }}>{cta}</Button>
      </Link>
    </Flex>
  )
}
