import { useCatalogQuery } from '@chordcommerce/gatsby-theme-autonomy/src/hooks/graphql/queries/use-catalog'
import { useEffect, useState } from 'react'

const useProductMainImage = ({ slug }) => {
  const { catalog: { collections = [] } = { collections: [] } } =
    useCatalogQuery()

  const products = collections.reduce(
    (acc, collection) => [...acc, ...collection.products],
    [],
  )

  const [image, setImage] = useState(null)

  useEffect(() => {
    const product = products?.find(p => p?.slug === slug)

    if (!product) return

    const image = product.mainImage

    if (image) setImage(image)
  }, [products, slug])

  return { image }
}

const useVariantMainImage = ({ slug, sku }) => {
  const { catalog: { collections = [] } = { collections: [] } } =
    useCatalogQuery()

  const products = collections
    .filter(collection => !!collection.products)
    .reduce((acc, collection) => [...acc, ...collection.products], [])

  const [image, setImage] = useState(null)

  useEffect(() => {
    const product = products?.find(p => p?.slug === slug)
    const variant = product?.variants?.find(variant => variant.sku === sku)

    if (!variant) return

    const image = variant.mainImage

    if (image) setImage(image)
  }, [products, slug])

  return { image }
}

const useProductOptions = ({ slug, sku }) => {
  const { catalog: { collections = [] } = { collections: [] } } =
    useCatalogQuery()

  const products = collections
    .filter(collection => !!collection.products)
    .reduce((acc, collection) => [...acc, ...collection.products], [])

  const [optionValues, setOptions] = useState([])

  useEffect(() => {
    const product = products?.find(p => p?.slug === slug)
    const selectedVariant = product?.variants?.find(
      variant => variant.sku === sku,
    )

    if (!selectedVariant) return

    const options = selectedVariant.optionValues

    if (options) setOptions(options)
  }, [products, sku, slug])

  return { optionValues }
}

export { useProductMainImage, useVariantMainImage, useProductOptions }
