export function intervalToWord(interval) {
  if (interval === 'month') {
    return 'monthly'
  }

  if (interval === 'year') {
    return 'yearly'
  }

  if (interval === 'week') {
    return 'weekly'
  }

  if (interval === 'day') {
    return 'daily'
  }

  return interval
}
