/** @jsx jsx */

import React, { useState, useContext, useEffect } from 'react'
import { DateTime } from 'luxon'
import { Button, Flex, jsx, Input, Textarea } from 'theme-ui'
import Text from '~/components/IntlComponents/Text'
import MessageContext from '~/contexts/MessageContext'
import useLocaleCartLogic from '~/utils/intl/context/hooks/use-locale-cart-logic'

export default function GiftCardInputs({
  giftCards,
  updateGiftCardSubmission,
}) {
  const [, setMessage] = useContext(MessageContext)
  const { modifyGiftCards } = useLocaleCartLogic()
  const today = DateTime.now()
  const [sendDate, setSendDate] = useState(
    `${today.c.year}-${today.c.month}-${today.c.day}`,
  )
  const [recipientEmail, setRecipientEmail] = useState(
    giftCards[0].recipientEmail,
  )
  const [purchaserName, setPurchaserName] = useState(giftCards[0].purchaserName)
  const [giftMessage, setGiftMessage] = useState(giftCards[0].giftMessage)

  const [dataSubmitted, setDataSubmitted] = useState(false)

  const validateEmail = email => {
    if (email !== null) {
      const pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
      )

      if (!pattern.test(email)) {
        return false
      }
      return true
    }
    return false
  }

  const handleSubmit = async () => {
    if (validateEmail(recipientEmail) === true) {
      await giftCards.forEach(card => {
        updateGiftCardSubmission(card.id)
        modifyGiftCards({
          giftCards: {
            giftCardsDetails: [
              {
                recipientEmail,
                giftMessage,
                purchaserName,
                sendEmailAt: sendDate,
                id: card.id,
              },
            ],
          },
        })
          .then(r => {
            setDataSubmitted(true)
            setMessage('Gift Card Info Updated')
          })
          .catch(error => {
            console.log(error)
            setMessage('Error updating gift card info')
          })
      })
    } else {
      setMessage('Error updating gift card info, please enter a valid email')
    }
  }
  useEffect(() => {
    if (!!giftCards[0].filled) {
      setDataSubmitted(true)
    }
  }, [giftCards])

  return (
    <Flex
      sx={{
        mt: '1.5rem',
        flexDirection: ['column'],
        position: 'relative',
        width: ['max-content'],
        color: 'sunsetOrange',
      }}
    >
      {/* Email / Date Container */}
      <Flex
        sx={{
          flexDirection: ['column', 'row', 'row'],
          alignItems: ['flex-start', 'flex-end', 'flex-end'],
        }}
      >
        {/* Date */}
        <Flex
          sx={{
            flexDirection: 'column',
            width: ['300px', '240px', '240px'],
          }}
        >
          <Flex sx={{ alignItems: 'center' }}>
            <Text>Send Date</Text>
            <Text sx={{ ml: '6px', fontSize: '0.8rem', fontStyle: 'italic' }}>
              (leave blank to use today)
            </Text>
          </Flex>
          {dataSubmitted ? (
            <Text>{sendDate}</Text>
          ) : (
            <Input
              type="date"
              defaultValue={`${today.c.year}-${today.c.month}-${today.c.day}`}
              onChange={e => setSendDate(e.target.value)}
              sx={{
                backgroundColor: 'white',
                maxWidth: [null, null, '250px'],
                width: '100%',
                height: '44px',
                fontFamily: 'sofia',
              }}
            />
          )}
        </Flex>
        {/* Email */}
        <Flex
          sx={{
            flexDirection: 'column',
            width: ['300px', '180px', '300px'],
            ml: [0, '10px', '10px'],
          }}
        >
          <Text sx={{ mt: ['12px', 0, 0] }}>Recipient's Email</Text>
          <Flex>
            {dataSubmitted ? (
              <Text>{recipientEmail}</Text>
            ) : (
              <Input
                name="Email"
                title="Email"
                label="Email"
                placeholder="Email"
                aria-label="Email"
                onChange={e => setRecipientEmail(e.target.value)}
                sx={{
                  height: '44px',
                  backgroundColor: 'white',
                  width: '100%',
                  border: 'governorBay',
                  fontFamily: 'sofia',
                  fontWeight: '400',
                }}
              />
            )}
          </Flex>
        </Flex>
      </Flex>

      <Flex
        sx={{
          mt: '12px',
          alignItems: ['flex-start'],
          justifyContent: 'space-between',
          flexDirection: ['column', 'row', 'row'],
        }}
      >
        {/* Message */}
        <Flex
          sx={{
            flexDirection: 'column',
            width: ['300px', '240px', '240px'],

            mr: [0, '10px', '10px'],
          }}
        >
          {/* TODO Localize */}
          <Text>Message (optional)</Text>
          <Flex>
            {dataSubmitted ? (
              <Text sx={{ fontStyle: 'italic' }}>{giftMessage}</Text>
            ) : (
              <Textarea
                name="Message"
                title="Message"
                label="Message"
                placeholder="Message"
                aria-label="Message"
                onChange={e => setGiftMessage(e.target.value)}
                sx={{
                  backgroundColor: 'white',
                  width: '100%',
                  height: '100px',
                  resize: 'none',
                  maxWidth: [null, null, '300px'],
                  fontFamily: 'sofia',
                  fontWeight: '400',
                  color: 'sunsetOrange',
                }}
              />
            )}
          </Flex>
        </Flex>
        {/* Purchaser Name */}
        <Flex
          sx={{
            flexDirection: 'column',
            width: ['300px', '180px', '300px'],
          }}
        >
          {/* TODO Localize */}
          <Text sx={{ mt: ['12px', 0, 0] }}>Your Name (optional)</Text>
          <Flex>
            {dataSubmitted ? (
              <Text>{purchaserName}</Text>
            ) : (
              <Input
                name="purchaserName"
                title="purchaserName"
                label="purchaserName"
                placeholder="Your Name"
                aria-label="purchaserName"
                onChange={e => setPurchaserName(e.target.value)}
                sx={{
                  height: '44px',
                  backgroundColor: 'white',
                  width: '100%',
                  border: 'governorBay',
                  fontFamily: 'sofia',
                  fontWeight: '400',
                }}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
      {/* TODO Localize */}
      <Flex sx={{ mt: ['10px'] }}>
        {!dataSubmitted ? (
          <Button
            sx={{
              fontWeight: 'bold',
              width: '125px',
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                color: 'sunsetOrange',
                backgroundColor: 'governorBay',
              },
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        ) : (
          <Button
            sx={{
              fontWeight: 'bold',
              width: '125px',
              '&:hover': {
                color: 'sunsetOrange',
                backgroundColor: 'governorBay',
              },
            }}
            onClick={() => setDataSubmitted(false)}
          >
            {/* TODO Localize */}
            Edit
          </Button>
        )}
      </Flex>
    </Flex>
  )
}
